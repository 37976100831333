<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item 
            v-if="activeTab==1"
            prop="financial_account_id" label="财务账户">
          <a-select style="width:220px;" placeholder="请选择账户"
               v-model="financial_account_id"
               @change="changeAccount">
            <!-- <a-select-option value>请选择</a-select-option> -->
            <a-select-option v-for="(financeAccount, index) in financeAccountList"
                 :value="financeAccount.id"
                 :key="index">{{financeAccount.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="search_date" label="选择时间">
          <a-range-picker
            style="width:300px"
            v-model="search_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="mt-2">
      <a-tabs type="card" class="w-full" @change="changeTabs" :activeKey="activeTab">
        <a-tab-pane :key="0" tab="结算表"></a-tab-pane>
        <a-tab-pane :key="1" tab="财务账户"></a-tab-pane>
      </a-tabs>
    </div>

    <template v-if="activeTab==0">
      <div class="text-sm mt-4 mb-2">结算表（单位：元）</div>
      <base-table style="white-space: pre-line;"
				:columnsData="columns_settle"
				:tableData="tableData_settle"
      ></base-table>
    </template>

    <template v-if="activeTab==1">
			<div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”订单总览（根据下单时间筛选）</div>
			<base-table style="max-width:800px;"
				:columnsData="columns_order"
				:tableData="tableData_order"
			></base-table>
			<div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”退费数据（根据退费时间筛选）</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_refund"
				:tableData="tableData_refund"
			></base-table>
			<div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”订单来源分布（单位:笔）</div>
			<base-table style="max-width:900px;"
				:columnsData="columns_source"
				:tableData="tableData_source"
			></base-table>
			<div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”支付方式分布（单位:元）</div>
			<base-table 
				:columnsData="columns_pay"
				:tableData="tableData_pay"
			></base-table>
    </template>

		
  </div>
</template>

<script>
import pageData from "./columns"
import {
  getList,
} from "@/api/system/account.js"
import { 
	financeGroupSettle,
	financeGroupAccountOrder,
	financeGroupAccountVipcard
} from "@/api/finance/finance.js"
import { formatEnMoney } from "@/utils/index.js"

export default {
  data() {
    return {
			activeTab: 0,
			search_date: [],
			financial_account_id: '',

			financeAccountList: '',
			selectAccount: {},

			...pageData,
			tableData_order: [],
			columns_order: [
				{
					title: "有效订单数",
					dataIndex: "place_order_count",
					align: "center",
				},
				{
					title: "有效订单总金额(元)",
					dataIndex: "order_amount",
					align: "center",
					slots: {
						customRender: "order_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "其中已完成订单数",
					dataIndex: "finish_count",
					align: "center",
				},
				{
					title: "已完成订单总金额(元)",
					dataIndex: "finish_amount",
					align: "center",
					slots: {
						customRender: "finish_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
					// slotsFunc: (val) => {return val ? val : "——"},
				},
			],
			tableData_refund: [],
			columns_refund: [
				{
					title: "退费订单数",
					dataIndex: "refund_order_count",
					align: "center",
				},
				{
					title: "退费金额(元)",
					dataIndex: "refund_amount",
					align: "center",
					slots: {
						customRender: "refund_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			tableData_pay: [],

			tableData_source: [],

			tableData_vipcard: [],
			columns_vipcard_recharge: [
				{
					title: "充值笔数",
					dataIndex: "recharge_order_count",
					align: "center",
				},
				{
					title: "充值面额(元)",
					dataIndex: "recharge_denomination",
					align: "center",
					slots: {
						customRender: "recharge_denomination",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "充值支付金额(元)",
					dataIndex: "recharge_pay_amount",
					align: "center",
					slots: {
						customRender: "recharge_pay_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			columns_vipcard_online: [
				{
					title: "在线消费笔数",
					dataIndex: "online_consumption_order_count",
					align: "center",
				},
				{
					title: "在线消费面额(元)",
					dataIndex: "online_consumption_amount",
					align: "center",
					slots: {
						customRender: "online_consumption_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "对应充值金额(元)",
					dataIndex: "online_consumption_recharge_discount_amount",
					align: "center",
					slots: {
						customRender: "online_consumption_recharge_discount_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			columns_vipcard_offline: [
				{
					title: "线下消费笔数",
					dataIndex: "offline_consumption_order_count",
					align: "center",
				},
				{
					title: "线下消费面额(元)",
					dataIndex: "offline_consumption_amount",
					align: "center",
					slots: {
						customRender: "offline_consumption_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "对应充值金额(元)",
					dataIndex: "offline_consumption_recharge_discount_amount",
					align: "center",
					slots: {
						customRender: "offline_consumption_recharge_discount_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			tableData_settle: [],
			columns_settle: [
				{
					title: "收款账户",
					dataIndex: "name",
					align: "center",
				},
				{
					title: "结算账户",
					dataIndex: "tname",
					align: "center",
				},
				{
					title: "订单结算\n应付",
					dataIndex: "amount_1",
					align: "center",
					slots: { customRender: "amount_1" },
					slotsType: "format",
					slotsFunc: (val) => { return Number(val) > 0 ? ("-"+formatEnMoney(val)) : formatEnMoney(val)},
				},
				{
					title: "订单结算\n应收",
					dataIndex: "amount_2",
					align: "center",
					slots: { customRender: "amount_2" },
					slotsType: "format",
					slotsFunc: (val) => { return formatEnMoney(val)},
				},
				{
					title: "订单退款\n应收",
					dataIndex: "amount_3",
					align: "center",
					slots: { customRender: "amount_3" },
					slotsType: "format",
					slotsFunc: (val) => { return formatEnMoney(val)},
				},
				{
					title: "订单退款\n应付",
					dataIndex: "amount_4",
					align: "center",
					slots: { customRender: "amount_4" },
					slotsType: "format",
					slotsFunc: (val) => { return Number(val) > 0 ? ("-"+formatEnMoney(val)) : formatEnMoney(val)},
				},
				{
					title: "总计",
					align: "center",
					slots: { customRender: "total_amount" },
					slotsType: "all",
					// slotsFunc: (val) => {return Math.round((-Number(val.amount_1)+Number(val.amount_2)+Number(val.amount_3)-Number(val.amount_4)-Number(val.amount_5.discount_amount)+Number(val.amount_6.discount_amount))*100)/100},
					slotsFunc: (val) => {return formatEnMoney(
									-Number(val.amount_1) + Number(val.amount_2) + Number(val.amount_3) - Number(val.amount_4),
									true
								)
					},
				},
			],

    }
  },
  mounted() {
    this.initData()

    this.search_date = [
			this.getThisMonthFirstDay(),
			this.getToday(),
		]
		this.handlerSearch()
  },
  methods: {
    changeTabs(v){
      this.activeTab = v
      this.handlerSearch()
    },

    async initData(){
			const { data } = await getList()
			this.financeAccountList = data.list
			this.financial_account_id = data.list[0].id
			this.selectAccount = data.list[0]
    },

    changeAccount(id){
			let finance = this.financeAccountList.find(el=> el.id == id )
			this.selectAccount = finance
			this.handlerSearch()
    },

    async initDataSettle() {
			const params = {
				time_start: this.search_date[0] + '00:00:00',
				time_end:   this.search_date[1] + '23:59:59'
			}
			const { data, code } = await financeGroupSettle(params)
			if (code === 0) {
				let newList = []
				data.list.map(fitem=>{
					fitem.other_financial_settle_accounts_list.map(el=>{
						let item = newList.find(a=>{
							return a.id == el.financial_account_id && a.tid == fitem.financial_account_id
						})
						if(!item){
							item = Object.assign({}, {
								id: fitem.financial_account_id,
								name: fitem.financial_account_name,
								tid: el.financial_account_id,
								tname: el.financial_account_name,
								amount_1: el.amount_1,
                amount_2: 0,
                amount_3: el.amount_3,
                amount_4: 0,
                amount_5: {
                  amount: 0,
                  discount_amount: 0,
                },
                amount_6: el.amount_6,
							})
							newList.push(item)
						}else{
							Object.assign(item, {
                amount_2: el.amount_1,
                amount_4: el.amount_3,
                amount_5: el.amount_6,
							})
						}
					})
				})
				this.tableData_settle = newList
			}
    },

    async initDataOrder() {
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + '00:00:00',
				time_end:   this.search_date[1] + '23:59:59'
			}
			const { data, code } = await financeGroupAccountOrder(params)
			if (code === 0) {
				// 支付方式分布 金额格式化
				for(let i in data.order_amount_data){
					if(i == "50_amount_data"){
						// data.order_amount_data[i] = formatEnMoney(data.order_amount_data[i])
						let _50_amount_data = data.order_amount_data[i]
						for(let j in _50_amount_data){
							_50_amount_data[j] = formatEnMoney(_50_amount_data[j])
						}
					}else{
						data.order_amount_data[i] = formatEnMoney(data.order_amount_data[i])
					}
				}
				this.tableData_order = [data]
				this.tableData_refund = [data]
				this.tableData_source = [data.order_source_data]
				this.tableData_pay = [data.order_amount_data]
			}
    },

    async initDataVipcard() {
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + '00:00:00',
				time_end:   this.search_date[1] + '23:59:59'
			}
			const { data, code } = await financeGroupAccountVipcard(params)
			if (code === 0) {
				this.tableData_vipcard = [data]
			}
    },

    // 搜索
		handlerSearch() {
			if(this.activeTab != 0){
				if(!this.financial_account_id){
					this.$message.warning("请选择账户")
					return
				}
			}
			if (this.search_date.length < 2) {
				this.$message.warning("请选择日期")
				return
			}

			if(this.activeTab == 0){
				this.initDataSettle()
			}else{
				this.initDataOrder()
				// this.initDataVipcard()
			}
		},

		// -----------
		getLastMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			// 1月：上一个月 是去年12月
			if(month == 0){
				month = 11
				year = year-1
			}else{
				month = month-1
			}
			return this.formatDate(new Date(year, month, 1))
    },
    getLastMonthLastDay(){
			let date = new Date()
			date.setTime(date.getTime() - date.getDate()*24*3600*1000)

			return this.formatDate(date)
    },
    getThisMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			return this.formatDate(new Date(year, month, 1))
    },
    getToday(){
			let date = new Date()
			return this.formatDate(date)
    },

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
</style>