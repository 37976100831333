import ajax from '@/utils/ajax.js'


// ---------------- 网点
/**
 * 网点交付订单：财务总览
 * @param {*} params 
 * @returns 
 */
export function financeOutletsCarry(params) {
  return ajax.post('/OutletsFinancialBill/outletsDeliver',params)
}

/**
 * 网点下单：订单财务总览
 * @param {*} params 
 * @returns 
 */
export function financeOutletsPlace(params) {
  return ajax.post('/OutletsFinancialBill/placeOrder',params)
}

/**
 * 网点交付订单：配送员分布
 * @param {*} params 
 * @returns 
 */
export function financeOutletsCourier(params) {
  return ajax.post('/OutletsFinancialBill/deliveryData',params)
}

/**
 * 网点交付订单：导出
 * @param {*} params 
 * @returns 
 */
export function exportOutletsCarry(params) {
  return ajax.openurl('/OutletsExportDataReport/orderGoods2',params)
}


// ---------------- 财务账户
/**
 * 财务账号：订单总览
 * @param {*} params 
 * @returns 
 */
export function financeAccountOrder(params) {
  return ajax.post('/FinancialAccountBill/orderOverview',params)
}


/**
 * 财务账号：待交付订单总览
 * @param {*} params 
 * @returns 
 */
export function financeAccountWaitingOrder(params) {
  return ajax.post('/FinancialAccountBill/undeliveredOrderSummary',params)
}


/**
 * 财务账号：会员卡总览
 * @param {*} params 
 * @returns 
 */
export function financeAccountVipcard(params) {
  return ajax.post('/FinancialAccountBill/vipCardOverview',params)
}

/**
 * 财务账号：与其他账户之间的结算
 * @param {*} params 
 * @returns 
 */
export function financeAccountSettle(params) {
  return ajax.post('/FinancialAccountBill/andOtherFinancialSettleAccounts',params)
}

/**
 * 财务账号：旺店通默认账户的明细
 * @param {*} params 
 * @returns 
 */
export function financeAccountWDTSettleDetail(params) {
  return ajax.post('/FinancialAccountBill/wdtAndOtherFinancialSettleAccountsBill',params)
}

/**
 * 财务账号：明细导出：线上订单流水
 * @param {*} params 
 * @returns 
 */
export function financeAccountExportOrderFlow(params) {
  return ajax.openurl('/FinancialAccountBillExport/orderFlow',params)
}

/**
 * 财务账号：明细导出：会员卡流水
 * @param {*} params 
 * @returns 
 */
export function financeAccountExportVipCardFlow(params) {
  return ajax.openurl('/FinancialAccountBillExport/vipCard',params)
}

/**
 * 财务账号：明细导出：配送员业绩（规则：配送员所在网点的财务账户）
 * @param {*} params 
 * @returns 
 */
export function financeAccountExportCourierAchievement(params) {
  return ajax.openurl('/FinancialAccountBillExport/courierAchievement',params)
}
/**
 * 财务账号：明细导出：线下门店核销
 * @param {*} params 
 * @returns 
 */
export function financeAccountExportOfflineFlow(params) {
  return ajax.openurl('/FinancialAccountBillExport/offlineOutletsVerification',params)
}

/**
 * 财务账号：内部结算导出：订单分成 应支出
 * @param {*} params 
 * @returns 
 */
export function financeAccountExportSettle1(params) {
  return ajax.openurl('/FinancialAccountBillExport/interiorFinancialSettleAccounts1',params)
}
/**
 * 财务账号：内部结算导出：订单分成 应收入
 * @param {*} params 
 * @returns 
 */
export function financeAccountExportSettle2(params) {
  return ajax.openurl('/FinancialAccountBillExport/interiorFinancialSettleAccounts2',params)
}
/**
 * 财务账号：内部结算导出：订单退款补偿 应收入
 * @param {*} params 
 * @returns 
 */
export function financeAccountExportSettle3(params) {
  return ajax.openurl('/FinancialAccountBillExport/interiorFinancialSettleAccounts3',params)
}
/**
 * 财务账号：内部结算导出：订单退款补偿 应支出
 * @param {*} params 
 * @returns 
 */
export function financeAccountExportSettle4(params) {
  return ajax.openurl('/FinancialAccountBillExport/interiorFinancialSettleAccounts4',params)
}
/**
 * 财务账号：内部结算导出：会员卡的消费 应支出
 * @param {*} params 
 * @returns 
 */
export function financeAccountExportSettle5(params) {
  return ajax.openurl('/FinancialAccountBillExport/interiorFinancialSettleAccounts5',params)
}
/**
 * 财务账号：内部结算导出：会员卡的消费 应收入
 * @param {*} params 
 * @returns 
 */
export function financeAccountExportSettle6(params) {
  return ajax.openurl('/FinancialAccountBillExport/interiorFinancialSettleAccounts6',params)
}


// ---------------- 区域城市
/**
 * 网点交付订单：财务总览
 * @param {*} params 
 * @returns 
 */
export function financeCityCarry(params) {
  return ajax.post('/RegionalFinancialBill/CityOutletsDeliver',params)
}


// ---------------- 总

/**
 * 总部：账户之间的结算
 * @param {*} params 
 * @returns 
 */
export function financeGroupSettle(params) {
  return ajax.post('/HQFinancialBill/financialBetweenSettleAccounts',params)
}

/**
 * 总部：某财务账户 的订单
 * @param {*} params 
 * @returns 
 */
export function financeGroupAccountOrder(params) {
  return ajax.post('/HQFinancialBill/financialOrderOverview',params)
}

/**
 * 总部：某财务账户 的会员卡
 * @param {*} params 
 * @returns 
 */
export function financeGroupAccountVipcard(params) {
  return ajax.post('/HQFinancialBill/financialVipCardOverview',params)
}
